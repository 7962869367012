// eslint-disable-next-line react/prop-types
function Error({ statusCode }) {
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <p>
      {statusCode
        ? `An error ${statusCode} occurred on server`
        : 'An error occurred on client'}
    </p>
  );
}

Error.getInitialProps = ({ res, err }) => {
  if (typeof window === 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
    const newrelic = require('newrelic');
    newrelic.noticeError(err);
  } else {
    window.newrelic.noticeError(err);
  }

  // eslint-disable-next-line no-nested-ternary
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default Error;
